// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-templates-fullscreen-tsx": () => import("./../../../src/templates/fullscreen.tsx" /* webpackChunkName: "component---src-templates-fullscreen-tsx" */),
  "component---src-templates-grid-tsx": () => import("./../../../src/templates/grid.tsx" /* webpackChunkName: "component---src-templates-grid-tsx" */)
}

